<template>
  <div>
    <div v-if="userInfo">
      <div v-if="_isPartner">
        <!-- <b-alert variant="danger" :show="!userInfo.approve_status_partner">
          <div class="alert-body">
            <span>
              <strong>พบปัญหาเกี่ยวกับบัญชี</strong> โปรดไปที่
              <b-link :to="{ name: 'partner-settings' }">
                ตั้งค่าบัญชี
              </b-link>
              เพื่อแก้ไขถึงจะเข้าใช้งานส่วนอื่นๆได้
            </span>
          </div>
        </b-alert> -->
        <partner-dashboard :user-id="userInfo.id" />
      </div>
      <admin-dashboard v-if="_isAdmin || _isMarketing" />
    </div>
    <b-spinner v-else />
    <!-- <pre>{{ userInfo }}</pre> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    PartnerDashboard: () => import('@/views/apps/partners/dashboard/PartnerDashboard.vue'),
    AdminDashboard: () => import('@/views/apps/admins/dashboard/AdminDashboard.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    ...mapGetters('AuthStore', ['_isAdmin', '_isPartner', '_isMarketing']),
  },
}
</script>

<style lang="scss" scoped></style>
